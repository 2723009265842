"use client";

import { store } from '@general/redux/store';
import { setLang } from '@general/redux/slices/lang'
import { Provider } from "react-redux";
import { useEffect } from 'react';
import { isOnServerside } from '@general/services/helper';
import { setIsMobile } from '@general/redux/slices/userAgent';
import { setGridSize } from '@general/redux/slices/width';
import { MD, MD_WIDTH, SM, SM_WIDTH, XL, XL_WIDTH, XS } from '@general/constants/general';
import { getAxiosInstance } from '@general/services/axios';
import { setCountryIso } from '@general/redux/slices/countryIso';
import { PostHogProvider } from '@general/components/client_side/providers/PostHogProvider';
let wasInited = false
export function Providers({
    children,
    lang,
    isMobile,
    countryIso
}: {
    children: React.ReactNode,
    lang: string,
    isMobile: boolean,
    countryIso: string | undefined
}) {

    async function detectCountry() {
        if (isOnServerside()) {
            return;
        }
        const axiosInstance = getAxiosInstance();
        const response = await axiosInstance.get('/api/detect_country');
        if (typeof response.data.success !== 'undefined') {
            store.dispatch(setCountryIso(response.data.iso_code.toLowerCase()));
        }
    }

    if (!wasInited || isOnServerside()) {
        wasInited = true;
        store.dispatch(setLang(lang))
        store.dispatch(setIsMobile(isMobile))
        if (isMobile) {
            store.dispatch(setGridSize(XS))
        } else {
            store.dispatch(setGridSize(XL))
        }

        // if (countryIso !== undefined) {
        //     store.dispatch(setCountryIso(countryIso))
        // } else {
        //     detectCountry().catch(console.error);
        // }

    }

    function handlerResize() {
        if (window.innerWidth < SM_WIDTH) {
            store.dispatch(setGridSize(XS))
        } else if (window.innerWidth < MD_WIDTH) {
            store.dispatch(setGridSize(SM))
        } else if (window.innerWidth < XL_WIDTH) {
            store.dispatch(setGridSize(MD))
        } else {
            store.dispatch(setGridSize(XL))
        }
    }
    useEffect(() => {
        handlerResize()
        window.addEventListener("resize", handlerResize)
        return () => {
            window.removeEventListener("resize", handlerResize)
        }
    });

    useEffect(() => {
        store.dispatch(setLang(lang))
    }, [lang])



    return (
        <Provider store={store}>
            <PostHogProvider>{children}</PostHogProvider>
        </Provider>
    )
}