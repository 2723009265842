export const fallbackLang = 'en'
export const languages = ['ua', fallbackLang]
export const defaultNS = 'common'
export function getOptions (lng = fallbackLang, ns = defaultNS) {
    return {
      // debug: true,
      supportedLngs: languages,
      fallbackLang,
      lng,
      fallbackNS: defaultNS,
      defaultNS,
      ns,
      returnNull: false,
      react: {
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['strong', 'i', 'span']
      }
    }
  }