'use client'

import { isOnServerside } from '@general/services/helper'
import { usePathname, useSearchParams } from 'next/navigation'
import posthog from 'posthog-js'
import { PostHogProvider as PHProvider, usePostHog } from 'posthog-js/react'
import { useEffect, ReactNode, Suspense } from 'react'

interface PostHogProviderProps {
    children: ReactNode
}
function PostHogPageView() {
    const pathname = usePathname()
    const searchParams = useSearchParams()
    const posthog = usePostHog()

    useEffect(() => {
        if (pathname && posthog) {
            let url = window.origin + pathname
            if (searchParams.toString()) {
                url = url + "?" + searchParams.toString();
            }
            posthog.capture('$pageview', { '$current_url': url })
        }
    }, [pathname, searchParams, posthog])

    return null
}

export function PostHogProvider({ children }: PostHogProviderProps) {
    useEffect(() => {
        if (!isOnServerside() && !posthog.__loaded) {
            posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || '', {
                api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://eu.i.posthog.com',
                person_profiles: 'identified_only',
                capture_pageview: false,
                capture_pageleave: true
            })
        }
    }, [])

    return <PHProvider client={posthog}>
        <Suspense fallback={null}>
            <PostHogPageView />
        </Suspense>
        {children}
    </PHProvider>
}
