export const XS_WIDTH = 0
export const SM_WIDTH = 576
export const MD_WIDTH = 768
export const LG_WIDTH = 992
export const XL_WIDTH = 1200
export const XS = 'xs'
export const SM = 'sm'
export const MD = 'md'
export const LG = 'lg'
export const XL = 'xl'
export const DEFAULT_COUNTRY_ISO = 'ua'
export const LANG_UA = 'ua';
export const LAND_EN = 'en';